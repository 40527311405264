import React from "react";
import logo from "../assets/logo.png";
import solar_arrow from "../assets/solar_arrow_blue.png";
import zigzag from "../assets/zigzag.png";
import cashout from "../assets/cashout.png";
import facebook from '../assets/socialMedia/facebook.png'
import instagram from "../assets/socialMedia/instagram.png"
import linkedIn from "../assets/socialMedia/linkedIn.png"
import twitter from "../assets/socialMedia/twitter.png"

const Footer = () => {
  return (
    <div className="bg-[#002A51] text-white">
      <div className="container">
        <div className="grid md:grid-cols-3">
          <div className="py-10 md:border-r border-white">
            <div className="flex items-center mb-4">
              <img src={logo} alt="" />
              <h3 className="text-white heading-1 text-[30px]">TEAPPOS</h3>
            </div>
            <p className="font-[300] md:w-2/3">
              The Simple, Secure and Scalable Way to Run Your Business
            </p>
            <img src={zigzag} alt="" className="mt-7" />
            <div className="bg-[#fff] w-max rounded-full p-2 mt-7">
              <img src={solar_arrow} alt="" />
            </div>
          </div>
          <div className="col-span-2 pl-10 pt-14 pb-3">
            <div className="flex lg:flex-row flex-col gap-7 items-start ">
              <div>
                <h6 className="text-[20px] font-[500]">Services</h6>
                <ul className="font-[300]">
                  <li>POS Hardware Services</li>
                  <li>POS Software Services</li>
                  <li>Payment Processing</li>
                  <li>Inventory Management</li>
                  <li>Reporting & Analytics</li>
                  <li>Technical Support & Maintenance</li>
                  <li>Installation & Setup</li>
                </ul>
              </div>

              <div>
                <h6 className="text-[20px] font-[500]">Contact</h6>
                <ul className="font-[300]">
                  <li>teappos@epahubb.com</li>
                  <li>+233 531 178 671</li>
                  <li>+233 538 999 972</li>
                </ul>
              </div>

              <div>
                <h6 className="text-[20px] font-[500]">Locate Us</h6>
                <ul className="font-[300]">
                  <li>AK-359-7809 Panpan Street</li>
                  <li>Agric Nsema</li>
                  <li>Kumasi Ghana</li>
                </ul>
              </div>
              <img src={cashout} alt="" className="h-[150px] 8xl:flex hidden" />
            </div>

            <div className="border-t border-white pt-4 mt-6 flex justify-between">
              <p>&copy; Epahubb 2024</p>
              <div className="flex gap-2">
                <a
                  href="https://web.facebook.com/profile.php?id=100086835577380"
                  className="h-[33px] w-[33px] border border-white rounded-full flex justify-center items-center"
                >
                  <img src={facebook} alt="facebook" />
                </a>
                <a
                  href="https://twitter.com/epahubb"
                  className="h-[33px] w-[33px] border border-white rounded-full flex justify-center items-center"
                >
                  <img src={twitter} alt="facebook" />
                </a>
                <a
                  href=""
                  className="h-[33px] w-[33px] border border-white rounded-full flex justify-center items-center"
                >
                  <img src={linkedIn} alt="facebook" />
                </a>
                <a
                  href="https://www.instagram.com/epahubbconsultghana/"
                  className="h-[33px] w-[33px] border border-white rounded-full flex justify-center items-center"
                >
                  <img src={instagram} alt="facebook" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
