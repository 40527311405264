import React from 'react'
import services_people from "../assets/services_people.png"
import approved from "../assets/approved.png"

const Services = () => {
  return (
    <div id='services' className=' bg-[#D7DDCB] py-20'>
        <div className='rounded-2xl container min-h-screen p-10'>
            <h6 className='mb-6'>
                <span className='text-[#FE5B2C]'>//</span> <span className='italic text-[#002A51]'>02 - Services</span>
            </h6>
            <h2 data-aos-duration="1000" data-aos="fade-up" data-aos-easing="ease-in-out" className='text-[#002A51] heading-1 text-[45px] font-[400] text-center lg:w-2/3 leading-tight m-auto'>
                Tailored POS Solutions for Your Unique Store
            </h2>
            <div className="mt-20">
                <div className="grid lg:grid-cols-2 gap-6">
                    <div data-aos-duration="1000" data-aos="fade-right" data-aos-easing="ease-in-out" className='flex items-center justify-center'>
                        <img src={services_people} alt="" />
                    </div>
                    <div>
                        <h5 className='text-[#002A51] font-[500] text-[31px] 8xl:mt-8 mb-3'>
                            Empowering Business Key Feature
                        </h5>
                        <p>
                            TEAPPOS equips organizations with the tools and support necessary to enhance operations, drive growth and also to ensure success and efficiency.
                        </p>
                        <div data-aos-duration="1000" data-aos="fade-up" data-aos-easing="ease-in-out" className="grid grid-cols-2 gap-5 8xl:mt-16 mt-4">
                            <div className='flex items-start gap-2'>
                                <img src={approved} alt="" />
                                <div>
                                    <h5 className='font-[500] 8xl:text-[20px]'>Payment Processing:</h5>
                                    <p className='text-[14px]'>
                                        Advanced security features to protect your business and customer data.
                                    </p>
                                </div>
                            </div>
                            <div className='flex items-start gap-2'>
                                <img src={approved} alt="" />
                                <div>
                                    <h5 className='font-[500] 8xl:text-[20px]'>Inventory Management:</h5>
                                    <p className='text-[14px]'>
                                        Tools to help you monitor stock levels, manage suppliers, and streamline reordering.
                                    </p>
                                </div>
                            </div>
                            <div className='flex items-start gap-2'>
                                <img src={approved} alt="" />
                                <div>
                                    <h5 className='font-[500] 8xl:text-[20px]'>Reporting & Analysis:</h5>
                                    <p className='text-[14px]'>
                                        Comprehensive reports to analyze sales trends and make informed business decisions.
                                    </p>
                                </div>
                            </div>
                            <div className='flex items-start gap-2'>
                                <img src={approved} alt="" />
                                <div>
                                    <h5 className='font-[500] 8xl:text-[20px]'>Technical Support & Maintenance:</h5>
                                    <p className='text-[14px]'>
                                        Proactive system checks and updates to keep your POS running smoothly.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Services