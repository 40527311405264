import React from 'react'
import group from '../assets/Hero/group.png'
import women from "../assets/Hero/women.png"

const Hero = () => {
  return (
    <div id='home' className='container pt-20'>
        <div className="grid md:grid-cols-2  py-10 gap-[20px]">
            <div className='bg-[#001F3C] rounded-2xl flex items-center 8xl:py-[100px] md:py-10 py-[70px] 8xl:pl-24 md:pl-5 pl-12 md:pr-0 pr-12 text-white'>
                <div>
                    <div className="px-16 py-2 border border-white rounded-full w-max text-white">
                        TEAPPOS
                    </div>
                    <h2 data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos="fade-up" className='heading-1 lg:text-[40px] md:text-[20px] text-[30px] leading-tight mt-[22px] mb-[19px]'>
                        The Simple, Secure, and Scalable Way to Run Your Business
                    </h2>
                    <p data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos="fade-up" className='8xl:w-[90%] text-[15px]'>
                        Let’s get your business started with TeapPos.Simplifying Sales, Maximizing Success
                    </p>
                    <div className="flex items-center mt-5">
                        <div className="px-6 py-2 text-white bg-[#FE5B2C] rounded-full ">
                            More about us
                        </div>
                        <div className="h-[43px] w-[43px] rounded-full bg-[#FE5B2C]"></div>
                    </div>
                </div>
            </div>
            <div className="relative hidden md:block">
                <img data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos="fade-down" src={group} alt="" className='absolute top-0 left-0'/>
                <img data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos="fade-left" src={women} alt="" className='absolute 8xl:-bottom-3 8xl:right-[10px] -right-2 md:bottom-1 bottom-5 8xl:h-[317px] lg:h-[240px] md:h-[185px] h-[250px]'/>
                <div  data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos="fade-up" className="grid grid-cols-2 gap-3 absolute bottom-0 left-0 w-[63%] ">
                    <div className="bg-[#D8E7EF] rounded-2xl 8xl:p-[22px] p-[12px] 8xl:min-h-[210px] lg:min-h-[180px] md:min-h-[100px] min-h-[200px] ">
                        <h3 className="heading-1 8xl:text-[43px] lg:text-[30px] md:text-[20px] text-[30px]">
                            100 %
                        </h3>
                        <p className='font-[400] lg:text-[16px] md:text-[14px]'>
                            Secured POS system for your business
                        </p>
                    </div>
                    <div className="bg-[#D8E7EF] rounded-2xl 8xl:p-[22px] p-[12px]">
                        <h3 className="heading-1 8xl:text-[43px] lg:text-[30px] md:text-[20px] text-[30px]">
                            300 +
                        </h3>
                        <p className='font-[400]'>
                            Customer Satisfaction
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Hero