import React from 'react'

const Contact = () => {
  return (
    <div id="contact" className="md:min-h-screen lg:pt-40 pt-10 pb-16 mb-3 md:mb-0 bg-[#D8EFDA4D]">
      <div className="container grid lg:grid-cols-3 md:gap-10">
        <div>
          <h3 className="md:text-[90px] lg:text-[60px] heading-2 text-[60px] text-center text-[#091542] font-[200] md:leading-[80px] leading-tight mb-20 px-10">Let's contact for better result</h3>
          <div className="text-center mt-20 flex flex-col gap-3">
            <a href="" className="text-[#091542] font-[500] underline">teappos.epahubb.com</a>
            <p>AK-359-7809 Panpan Street </p>
            <p>Agric Nzema </p>
            <p> Kumasi Ghana</p>
          </div>
        </div>
        <form action="" className="col-span-2 mt-5 md:mt-0">
          <div className="flex flex-col">
            <label className="text-[#091542]" htmlFor="">Name</label>
            <input
              type="text"
              name=""
              id=""
              className="border border-[#8D8D8D] rounded px-4 py-3 bg-transparent"
            />
          </div>
          <div className="grid md:grid-cols-2 mt-4 gap-5">
            <div className="flex flex-col">
              <label className="text-[#091542]" htmlFor="">Email</label>
              <input
                type="email"
                name=""
                id=""
                className="border border-[#8D8D8D] rounded px-4 py-3 bg-transparent"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-[#091542]" htmlFor="">Phone</label>
              <input
                type="text"
                name=""
                id=""
                className="border border-[#8D8D8D] rounded px-4 py-3 bg-transparent"
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 mt-4 gap-5">
            <div className="flex flex-col">
              <label className="text-[#091542]" htmlFor="">Company</label>
              <input
                type="text"
                name=""
                id=""
                className="border border-[#8D8D8D] rounded px-4 py-3 bg-transparent"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-[#091542]" htmlFor="">Address</label>
              <input
                type="text"
                name=""
                id=""
                className="border border-[#8D8D8D] rounded px-4 py-3 bg-transparent"
              />
            </div>
          </div>
          <div className="flex flex-col mt-4">
            <label className="text-[#091542]" htmlFor="">Website</label>
            <input
              type="text"
              name=""
              id=""
              className="border border-[#8D8D8D] rounded px-4 py-3 bg-transparent"
            />
          </div>
          <div className="flex flex-col mt-4">
            <label className="text-[#091542]" htmlFor="">Message</label>
            <textarea className="border border-[#8D8D8D] rounded px-4 py-3 bg-transparent" placeholder='Write your message here' name="" id="" cols="30" rows="10"></textarea>
            
          </div>
          <button className="bg-[#1C274E] text-white rounded-full px-9 py-4 mt-4">
            Submit Now
          </button>
        </form>
      </div>
    </div>
  )
}

export default Contact