import React from 'react'
import full_logo from "../assets/full_logo.png"

const Banner = () => {
  return (
    <div className='container'>
        <div className="rounded-[40px] py-3 bg-[#F3F3F3]">
            <marquee>

                <div className=" flex gap-5 items-center justify-center overflow-auto">
                    <div className="flex  gap-1 items-center justify-center w-full">
                        <img src={full_logo} alt="" className='h-[40px]' />
                    </div>
                    <div className="flex  gap-1 items-center justify-center w-full">
                        <img src={full_logo} alt="" className='h-[40px]' />
                    </div>
                    <div className="flex  gap-1 items-center justify-center w-full">
                        <img src={full_logo} alt="" className='h-[40px]' />
                    </div>
                    <div className="flex  gap-1 items-center justify-center w-full">
                        <img src={full_logo} alt="" className='h-[40px]' />
                    </div>
                    <div className="flex  gap-1 items-center justify-center w-full">
                        <img src={full_logo} alt="" className='h-[40px]' />
                    </div>
                    <div className="flex  gap-1 items-center justify-center w-full">
                        <img src={full_logo} alt="" className='h-[40px]' />
                    </div>
                    <div className="flex  gap-1 items-center justify-center w-full">
                        <img src={full_logo} alt="" className='h-[40px]' />
                    </div>
                    <div className="flex  gap-1 items-center justify-center w-full">
                        <img src={full_logo} alt="" className='h-[40px]' />
                    </div>
                    
                </div>
            </marquee>
        </div>
    </div>
  )
}

export default Banner