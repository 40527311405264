import React from 'react'
import solar_arrow from "../assets/solar_arrow.png"
import zigzag from "../assets/zigzag.png"
import stat_data from "../assets/stat_data.png"

const Success = () => {
  return ( 
    <div className=' rounded-2xl bg-[#D8E7EF] p-10 mx-10'>
        <div className="container">
            <h6 className='mb-6 '>
                <span className='text-[#FE5B2C]'>//</span> <span className='italic text-[#002A51]'>03 - Success</span>
            </h6>
            <div className="grid md:grid-cols-3 gap-10">
                <div data-aos-duration="1000" data-aos="fade-up" data-aos-easing="ease-in-out">
                    <h4 className='heading-1 text-[#002A51] 8xl:text-[60px] text-[40px] 8xl:leading-[60px] leading-[50px]'>
                        Customer satisfaction data
                    </h4>
                    <img src={zigzag} alt="" className='mt-7' />
                    <div className='bg-[#002A51] w-max rounded-full p-2 mt-7'>
                        <img src={solar_arrow} alt="" />
                    </div>
                </div>
                <div data-aos-duration="1000" data-aos="fade-up" data-aos-easing="ease-in-out">
                    <img src={stat_data} alt="" />
                </div>
                <div data-aos-duration="1000" data-aos="fade-left" data-aos-easing="ease-in-out" className='text-[#002A51] font-[500] py-10 px-10 border-l-2 border-gray-400'>
                    <p>60% Clients Very Satisfied</p>
                    <p>35% Clients Statisfied</p>
                    <p>5% Neutral</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Success