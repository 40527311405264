import React, { useState } from 'react'
import Logo from "../assets/logo.png"
import { IoMenu } from "react-icons/io5";

const Navbar = () => {
    const [openSidebar, setOpenSidebar] = useState(false)
  return (
    <div className='py-5 rounded-br-[30px] rounded-bl-[30px] custom-shadow fixed top-0 left-0 w-full bg-white z-[999]'>
        <IoMenu className='absolute text-[30px] left-8 top-6 text-[#17858B] lg:hidden' onClick={()=>setOpenSidebar(!openSidebar)} />
        <div className="container flex lg:justify-between justify-center items-center">
            {/* LOGO */}
            <div className="flex items-center gap-2">
                <img src={Logo} alt="TeapPOS" className='h-12' />
                <h4 className='text-[#002A51] text-[30px] heading-1'>TEAPPOS</h4>
            </div>

            <nav>
                <ul className={`custom-transition flex flex-col lg:flex-row lg:items-center lg:gap-8 gap-4 fixed lg:static top-0 text-[#002A51] lg:mt-0 mt-20 font-[500] ${openSidebar ? "left-0" : "-left-[300px] lg:left-0"} w-[250px] lg:w-full h-screen lg:h-auto lg:bg-transparent bg-white pt-[40px] lg:pt-0 px-[20px] lg:px-0`}>
                    <li>
                        <a href="#home">
                            Home
                        </a>
                    </li>
                    <li>
                        <a href="#about">
                            About Us
                        </a>
                    </li>
                    <li>
                        <a href="#services">
                            Services
                        </a>
                    </li>
                    <li>
                        <a href="#pricing">
                            Pricing
                        </a>
                    </li>
                    <li>
                        <a href="#contact">
                            Contact Us
                        </a>
                    </li>
                    <a href="https://app.teappos.com/login" className='bg-[#002A51] text-white font-[500] px-7 py-2 rounded-[300px] lg:hidden text-center'>
                        Sign In
                    </a>
                </ul>
            </nav>

            <a href="https://app.teappos.com/login" className='bg-[#002A51] text-white font-[500] px-7 py-2 rounded-[300px] hidden lg:block text-center'>
                Sign In
            </a>
        </div>
    </div>
  )
}

export default Navbar