import React from 'react'

const Experience = () => {
  return (
    <div className='container py-10'>
        <h4 data-aos-duration="1000" data-aos="fade-up" data-aos-easing="ease-in-out" className='heading-1 mb-6 text-[#002A51] text-[45px] text-center 8xl:w-2/3 mx-auto leading-tight'>
            We consulted more than 5k+ popular companies world-wide
        </h4>
        <div className="flex flex-col md:flex-row flex-wrap">
            <div className='border-t border-gray-400 border-r w-24 hidden lg:inline-block'>

            </div>
            <div className='text-[#002A51] 8xl:w-[350px] border-t border-r border-gray-400 px-10 py-7 flex flex-col items-center justify-center'>
                <p data-aos-duration="1000" data-aos="fade-up" data-aos-easing="ease-in-out" className='heading-1 text-[50px] flex items-start '>
                    <span>3</span> <span>
                        <sup>+</sup>
                    </span>
                </p>
                <p data-aos-duration="1000" data-aos="fade-up" data-aos-easing="ease-in-out" className='leading-tight font-[500] text-[16px] w-2/3 mx-auto text-center'>
                    YEAR EXPERIENCES
                </p>
            </div>
            <div className='text-[#002A51] 8xl:w-[350px] border-t border-r border-gray-400 lg:px-10 py-7 flex flex-col items-center justify-center'>
                <p data-aos-duration="1000" data-aos="fade-up" data-aos-easing="ease-in-out"  className='heading-1 text-[50px] flex items-start '>
                    <span>25k</span> <span>
                        <sup>+</sup>
                    </span>
                </p>
                <p data-aos-duration="1000" data-aos="fade-up" data-aos-easing="ease-in-out"  className='leading-tight font-[500] text-[16px] w-2/3 mx-auto text-center'>
                    CLIENTS AROUND THE WORLD
                </p>
            </div>
            <div className='text-[#002A51] 8xl:w-[350px] border-t border-r border-gray-400 px-10 py-7 flex flex-col items-center justify-center'>
                <p data-aos-duration="1000" data-aos="fade-up" data-aos-easing="ease-in-out"  className='heading-1 text-[50px] flex items-start '>
                    <span>30</span> <span>
                        <sup>+</sup>
                    </span>
                </p>
                <p data-aos-duration="1000" data-aos="fade-up" data-aos-easing="ease-in-out" className='leading-tight font-[500] text-[16px] w-2/3 mx-auto text-center'>
                    TEAM MEMBERS
                </p>
            </div>
            <div className='border-t border-gray-400 w-24 hidden lg:inline-block'>

            </div>
        </div>
    </div>
  )
}

export default Experience