import React from 'react'
import desk_man from "../assets/desk_man.png"
import zero_one from "../assets/pricing/01.png"
import zero_two from "../assets/pricing/02.png"
import zero_three from "../assets/pricing/03.png"
import zero_four from "../assets/pricing/04.png"
import tick from "../assets/pricing/tick.png"


const Pricing = () => {
  return (
    <div className='container py-16' id='pricing'>
        <h6 className='mb-6'>
            <span className='text-[#FE5B2C]'>//</span> <span className='italic text-[#002A51]'>04 - Pricing</span>
        </h6>
        <div className="grid lg:grid-cols-2">
            <div className="flex flex-col">
                <div>
                    <h3 className='heading-1 text-[50px] text-[#002A51] w-2/3 leading-tight'>
                        The Perfect Pricing Plan Just For You...
                    </h3>
                    <div className="flex items-center mt-5">
                        <div className="px-6 py-2 text-white bg-[#002A51] rounded-full ">
                            More about us
                        </div>
                        <div className="h-[43px] w-[43px] rounded-full bg-[#002A51]"></div>
                    </div>
                    <img data-aos-duration="500" data-aos="fade-right" data-aos-easing="ease-in-out" src={desk_man} alt="" className='mt-10 hidden lg:flex' />
                </div>
            </div>
            <div className="grid md:grid-cols-2 gap-7 mt-4 lg:mt-0" data-aos-duration="1000" data-aos="fade-up" data-aos-easing="ease-in-out">
                <div className="border border-gray-400 rounded-2xl px-7 py-5">
                    <div className='border-b pb-7'>
                        <img src={zero_one} alt="" />
                        <p className='text-[28px] font-[300]'>Basic</p>
                        <p className='text-[30px] font-[500] heading-1'>
                            Ghc500.00 <span className='text-[13px] font-[300]'>/per month</span>
                        </p>
                    </div>
                    <div className='py-5 text-[#808080]'>
                        <div className='flex items-center gap-2'>
                            <img src={tick} alt="" />
                            1 User
                        </div>
                        <div className='flex items-center gap-2'>
                            <img src={tick} alt="" />
                            3 Employees
                        </div>
                        <div className='flex items-center gap-2'>
                            <img src={tick} alt="" />
                            100mb storage
                        </div>
                        <div className='flex items-center justify-center mt-20'>
                            <button className='bg-[#08C0E9] px-6 py-2 rounded-full text-white'>
                                Choose Plan
                            </button>
                        </div>
                    </div>
                </div>

                <div className="border border-gray-400 rounded-2xl px-7 py-5">
                    <div className='border-b pb-7'>
                        <img src={zero_two} alt="" />
                        <p className='text-[28px] font-[300]'>Silver</p>
                        <p className='text-[30px] font-[500] heading-1'>
                            Ghc700.00 <span className='text-[13px] font-[300]'>/per month</span>
                        </p>
                    </div>
                    <div className='py-5 text-[#808080]'>
                        <div className='flex items-center gap-2'>
                            <img src={tick} alt="" />
                            2 Users
                        </div>
                        <div className='flex items-center gap-2'>
                            <img src={tick} alt="" />
                            5 Employees
                        </div>
                        <div className='flex items-center gap-2'>
                            <img src={tick} alt="" />
                            300mb storage
                        </div>
                        <div className='flex items-center justify-center mt-20'>
                            <button className='bg-[#08C0E9] px-6 py-2 rounded-full text-white'>
                                Choose Plan
                            </button>
                        </div>
                    </div>
                </div>

                <div className="border border-gray-400 rounded-2xl px-7 py-5">
                    <div className='border-b pb-7'>
                        <img src={zero_three} alt="" />
                        <p className='text-[28px] font-[300]'>Gold</p>
                        <p className='text-[30px] font-[500] heading-1'>
                            Ghc850.00 <span className='text-[13px] font-[300]'>/per month</span>
                        </p>
                    </div>
                    <div className='py-5 text-[#808080]'>
                        <div className='flex items-center gap-2'>
                            <img src={tick} alt="" />
                            4 Users
                        </div>
                        <div className='flex items-center gap-2'>
                            <img src={tick} alt="" />
                            10 Employees
                        </div>
                        <div className='flex items-center gap-2'>
                            <img src={tick} alt="" />
                            500mb storage
                        </div>
                        <div className='flex items-center gap-2'>
                            <img src={tick} alt="" />
                            Free Website
                        </div>
                        <div className='flex items-center justify-center mt-20'>
                            <button className='bg-[#08C0E9] px-6 py-2 rounded-full text-white'>
                                Choose Plan
                            </button>
                        </div>
                    </div>
                </div>

                <div className="border border-gray-400 rounded-2xl px-7 py-5">
                    <div className='border-b pb-7'>
                        <img src={zero_four} alt="" />
                        <p className='text-[28px] font-[300]'>Platinum</p>
                        <p className='text-[30px] font-[500] heading-1'>
                            Ghc1000.00 <span className='text-[13px] font-[300]'>/per month</span>
                        </p>
                    </div>
                    <div className='py-5 text-[#808080]'>
                        <div className='flex items-center gap-2'>
                            <img src={tick} alt="" />
                            6 Users
                        </div>
                        <div className='flex items-center gap-2'>
                            <img src={tick} alt="" />
                            Unlimited Employees
                        </div>
                        <div className='flex items-center gap-2'>
                            <img src={tick} alt="" />
                            1tb storage
                        </div>
                        <div className='flex items-center gap-2'>
                            <img src={tick} alt="" />
                            Free Website
                        </div>
                        <div className='flex items-center justify-center mt-20'>
                            <button className='bg-[#08C0E9] px-6 py-2 rounded-full text-white'>
                                Choose Plan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Pricing