import React from 'react'
import zigzag from "../assets/zigzag.png"
import solar_arrow from "../assets/solar_arrow.png"
import about_man from "../assets/about_man.png"

const About = () => {
  return (
    <div id='about' className='container py-24'>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-10" data-aos-duration="1000" data-aos="fade-up" data-aos-easing="ease-in-out">
            <div>
                <h6 className='mb-6'>
                    <span className='text-[#FE5B2C]'>//</span> <span className='italic text-[#002A51]'>01 - About Us</span>
                </h6>
                <h2 className='8xl:text-[60px] text-[40px] 8xl:leading-[70px] leading-[50px] heading-1 text-[#002A51] mb-5'>
                    What to Know About Us - TeaPPOS
                </h2>
                <p className='8xl:text-[17px] text-[#002A51]'>
                    We believe that running a business should be simple and efficient. We specialize in providing innovative point of sale solutions designed to empower businesses of all sizes. With our state-of-the-art technology and user-friendly systems, we help streamline operations, enhance customer experiences, and drive sales.
                </p>
                <img src={zigzag} alt="" className='mt-7' />
                <div className='bg-[#002A51] w-max rounded-full p-2 mt-7'>
                    <img src={solar_arrow} alt="" />
                </div>
            </div>
            <div className='ml-8'>
                <h3 className='text-[#002A51] heading-1 flex gap-0 '> 
                    <span className='text-[140px] leading-[120px]'>1</span>
                    <span className='text-[20px]'>No. Solution</span>
                </h3>
                <p className='text-[16px] mb-10'>
                    Whether you’re a small business owner or a large enterprise, TEAPPOS is here to support your journey. Let us help you take your business to the next level with our comprehensive POS solutions.
                </p>
                <h3 className='text-[#002A51] heading-1 underline pb-2'>
                    WHY CHOOSE US
                </h3>
                <ul className='list-disc ml-5 heading-1'>
                    <li>
                        Innovative Solutions
                    </li>
                    <li>
                        Exceptional Support
                    </li>
                    <li>
                        User-Friendly Interface
                    </li>
                    <li>
                        Customization
                    </li>
                </ul>
                
            </div>
            <div className='hidden lg:flex'>
                <img src={about_man} alt="" />
            </div>
        </div>
    </div>
  )
}

export default About