import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Hero from '../sections/Hero'
import Banner from '../sections/Banner'
import About from '../sections/About'
import Services from '../sections/Services'
import Experience from '../sections/Experience'
import Success from '../sections/Success'
import Pricing from '../sections/Pricing'
import Contact from '../sections/Contact'

const Layout = () => {
  return (
    <div>
        <Navbar />
        <Hero />
        <Banner />
        <About />
        <Services />
        <Experience />
        <Success />
        <Pricing />
        <Contact />
        <Footer />
    </div>
  )
}

export default Layout